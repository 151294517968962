import { useState } from "react";

export const MemberEventsInferredFromNotes = ({ isOpen, onClose }) => {
  const [featureImage, setFeatureImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  if (!isOpen) return null;

  // Fetch image if not already loaded
  if (!featureImage) {
    fetch(`/api/image?url=${encodeURIComponent('https://storage.googleapis.com/lcrplus-images/feature_modals/scarlett-morgan-note.png')}`)
      .then(res => {
        if (res.ok) return res.blob();
        throw new Error('Failed to fetch image');
      })
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        setFeatureImage(imageUrl);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(`Error fetching photo:`, error);
        setIsLoading(false);
      });
  }

  if (isLoading) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ textAlign: 'left', position: 'relative' }}>
        <div
          style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px", zIndex: 20000 }}
          onClick={onClose}
        >
          <i className="bx bx-x" style={{ fontSize: "22px" }}></i>
        </div>
        <h2 style={{ textAlign: 'center' }}>🚀 Our first AI feature 🚀</h2>
        <br/>
        <p>When you take notes about members in your ward, AI will extract important dates and prompt you to follow up.</p>
        <br/>
        <p>For example, let's say you take a note about Scarlett Morgan: "She runs Track for BYU, and her first meet is on January 31st."</p>
        {featureImage && <img src={featureImage} alt="Our first AI feature" style={{ width: '100%', margin: '20px 0' }} />}
        <br/>
        <p>You will then receive the following message on January 31st:</p>
        <br/>
        <p style={{ backgroundColor: 'rgb(246, 247, 248)', padding: '10px', borderRadius: '4px' }}>📅 Follow-up 📅
        <br/>
        <br/>
        Scarlett Morgan has her first track meet for BYU.
        <br/>
        ➜ <a href="https://lcrplus.org/t/BQ3jl">https://lcrplus.org/t/BQ3jl</a></p>
      </div>
    </div>
  );
};