import { useState } from "react";

export const MissionsMap = ({ isOpen, onClose }) => {
  const [image, setImage] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(true);

  if (!isOpen) return null;

  // Fetch image if not already loaded
  if (!image) {
    fetch(`/api/image?url=${encodeURIComponent('https://storage.googleapis.com/lcrplus-images/feature_modals/missions-map.gif')}`)
      .then(res => {
        if (res.ok) return res.blob();
        throw new Error('Failed to fetch image');
      })
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        setImage(imageUrl);
        setIsLoadingImage(false);
      })
      .catch(error => {
        console.error(`Error fetching photo:`, error);
        setIsLoadingImage(false);
      });
  }

  if (isLoadingImage) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ textAlign: 'left', position: 'relative', maxWidth: '650px' }}>
        <div
          style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px", zIndex: 20000 }}
          onClick={onClose}
        >
          <i className="bx bx-x" style={{ fontSize: "22px" }}></i>
        </div>
        <h2 style={{ textAlign: 'center' }}>📍 Missions map 📍</h2>
        <br/>
        <p>Navigate to the "Member reports" tab, then select the "Served mission" report.</p>
        <br/>
        <p>Next to the options to select "list" or "grid" view, there is now the option to select "map" view.</p>
        <br/>
        <p>Visible to bishoprics, executive secretaries, and ward clerks.</p>
        <br/>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {image && <img src={image} alt="Missions map" style={{ width: '100%', maxWidth: '650px', border: '3px solid #ddd', borderRadius: '4px' }} />}
        </div>
      </div>
    </div>
  );
};