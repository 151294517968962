import { useState, useRef } from "react";

export const BishopInterviews = () => {
  const initialLoad = useRef(true);
  const [interviews, setInterviews] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [reason, setReason] = useState("");

  const getUpcomingDates = () => {
    const dates = [];
    
    availability.forEach(slot => {
      let date = new Date();
      let count = 0;
      
      while (count < 5) {
        if (date.getDay() === slot.day_of_week) {
          dates.push({
            date: new Date(date),
            startTime: slot.start_time,
            endTime: slot.end_time,
            duration_minutes: slot.duration_minutes
          });
          count++;
        }
        date.setDate(date.getDate() + 1);
      }
    });

    // Sort dates chronologically
    return dates.sort((a, b) => a.date - b.date);
  };

  if (initialLoad.current) {
    const loadData = async () => {
      const availabilityResponse = await fetch('/api/list_bishop_availability');
      const availabilityData = await availabilityResponse.json();
      setAvailability(availabilityData);

      const interviewsResponse = await fetch('/api/list_bishop_interviews');
      const interviewsData = await interviewsResponse.json();
      setInterviews(interviewsData);
    };

    loadData();
    initialLoad.current = false;
  }

  const handleButtonClick = (interview, duration_minutes) => {
    if (!isTimeSlotTaken(interview.interview_datetime)) {
      setSelectedInterview({
        ...interview,
        duration_minutes
      });
    }
  };

  const handleSchedule = () => {
    const interviewDate = new Date(selectedInterview.interview_datetime);
    const utcDate = new Date(Date.UTC(
      interviewDate.getFullYear(),
      interviewDate.getMonth(),
      interviewDate.getDate(),
      interviewDate.getHours(),
      interviewDate.getMinutes()
    ));

    fetch("/api/schedule_bishop_interview", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        interview_datetime: utcDate,
        duration_minutes: selectedInterview.duration_minutes,
        reason,
      }),
    }).then(() => {
      // Add the new interview to the interviews state
      setInterviews([...interviews, {
        interview_datetime: interviewDate,
        duration_minutes: selectedInterview.duration_minutes,
        reason
      }]);
      setSelectedInterview(null);
      setReason("");
    });
  };

  const isTimeSlotTaken = (datetime) => {
    return interviews.some(interview => {
      const interviewDate = new Date(interview.interview_datetime);
      return interviewDate.getTime() === datetime.getTime();
    });
  };

  const upcomingDates = getUpcomingDates();
  const groupedInterviews = {};

  upcomingDates.forEach(slot => {
    const dateStr = slot.date.toLocaleDateString('en-US', { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric'
    }).replace(',', '');

    if (!groupedInterviews[dateStr]) {
      groupedInterviews[dateStr] = [];
    }

    // Generate time slots based on duration_minutes
    let currentTime = new Date(`2000-01-01T${slot.startTime}`);
    const endTime = new Date(`2000-01-01T${slot.endTime}`);

    while (currentTime < endTime) {
      const interviewDate = new Date(slot.date);
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      interviewDate.setHours(hours, minutes, 0, 0);

      groupedInterviews[dateStr].push({
        interview_datetime: interviewDate,
        duration_minutes: slot.duration_minutes
      });

      currentTime = new Date(currentTime.getTime() + slot.duration_minutes * 60000);
    }
  });

  return (
    <div style={{ color: '#0E121B' }}>
      {Object.entries(groupedInterviews).map(([date, dayInterviews]) => (
        <div key={date} style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
          <div style={{ width: '120px', fontWeight: 'bold' }}>{date}</div>
          <div style={{ display: 'flex', gap: '8px' }}>
            {dayInterviews.map(interview => {
              const isDisabled = isTimeSlotTaken(interview.interview_datetime);
              return (
                <button
                  key={interview.interview_datetime}
                  className="interview-time-button"
                  onClick={() => handleButtonClick(interview, interview.duration_minutes)}
                  disabled={isDisabled}
                  style={{
                    padding: '8px 16px',
                    borderRadius: '4px',
                    border: '1px solid #B0B5C0',
                    backgroundColor: isDisabled ? '#eee' : '#fff',
                    cursor: isDisabled ? 'default' : 'pointer'
                  }}
                >
                  {new Date(interview.interview_datetime).toLocaleTimeString('en-US', { 
                    hour: 'numeric', 
                    minute: 'numeric'
                  })}
                </button>
              );
            })}
          </div>
        </div>
      ))}

      {selectedInterview && (
        <>
          <div className="modal-overlay"></div>
          <div className="modal">
            <div className="modal-content">
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                boxSizing: 'border-box',
                borderRadius: '8px',
                border: '1px solid #B0B5C0'
              }}>
                <textarea
                  autoFocus={true}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Additional details (optional)"
                  rows="4"
                  maxLength={1000}
                  style={{
                    border: 'none',
                    width: "100%",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    resize: "none",
                    boxSizing: 'border-box',
                    padding: '8px',
                    outline: 'none',
                    backgroundColor: 'transparent',
                    overflow: 'hidden',
                  }}
                />
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                marginTop: "15px"
              }}>
                <button
                  onClick={() => setSelectedInterview(null)}
                  style={{
                    padding: '4px 8px',
                    border: '1px solid #B0B5C0',
                    borderRadius: '4px',
                    background: 'white',
                    fontSize: '14px',
                    width: '100px',
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSchedule}
                  style={{
                    padding: '4px 8px',
                    border: 'none',
                    borderRadius: '4px',
                    background: '#2684FC',
                    color: 'white',
                    fontSize: '14px',
                    width: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  Schedule
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};