import Select from "react-select";
import { formatName } from "../utils";
import { NoProfilePicture } from "../shared/NoProfilePicture";

export const AddReleaseCard = ({
  membersWithCallings,
  handleReleaseMember,
  unitDetails,
}) => {
  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "2px", cursor: "pointer", borderBottom: "0.5px solid #e0e0e0" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "50px", height: "50px", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {data.member_photo || data.household_photo ? <img src={data.member_photo || data.household_photo} alt={data.label} style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "5px", border: "0.5px solid #B0B5C0" }} /> : <NoProfilePicture />}
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <span style={{ flex: 1, textAlign: "left", fontWeight: "semibold" }}>{formatName(data.label)}</span>
            <span style={{ flex: 1, textAlign: "left", fontSize: "13px", color: "#666", fontStyle: "italic" }}>{data.position}</span>
          </div>
        </div>
      </div>
    );
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "15px",
      textAlign: "left",
      margin: "6px",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      minWidth: "210px",
      left: "50%",
      transform: "translateX(-50%)",
      maxHeight: "240px",
      overflowY: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
  };

  return (
    <div
      className="member-card"
      style={{
        border: "3px solid #B0B5C0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Select
        onChange={(option) => handleReleaseMember(option.value, true)}
        options={membersWithCallings.filter(row => !row.is_pending_release).map(row => ({
          ...row,
          label: formatName(row.member_name),
          value: row.calling_id,
        }))}
        placeholder={unitDetails.language === "es" ? "Despedir" : "Release"}
        styles={selectStyles}
        components={{ Option: CustomOption }}
      />
    </div>
  );
};