import { MemberEventsInferredFromNotes } from "./MemberEventsInferredFromNotes";
import { MovedOutField } from "./MovedOutField";
import { Attendance } from "./Attendance";
import { FlashcardsLeaderboard } from "./FlashcardsLeaderboard";
import { ActivityLevel } from "./ActivityLevel";
import { CallingGroupChats } from "./CallingGroupChats";
import { MinisteringGroupChats } from "./MinisteringGroupChats";
import { NewCallingColumns } from "./NewCallingColumns";
import { SundayProgram } from "./SundayProgram";
import { RelatedHymns } from "./RelatedHymns";
import { RoommatesInTheOtherWard } from "./RoommatesInTheOtherWard";
import { StakeCallings } from "./StakeCallings";
import { MissionsMap } from "./MissionsMap";
import { IncomingMemberSurveyResponse } from "./IncomingMemberSurveyResponse";
import { FlashcardsMatchMode } from "./FlashcardsMatchMode";
import { SundayProgramHistory } from "./SundayProgramHistory";
import { IncomingMemberSurveyResponseReport } from "./IncomingMemberSurveyResponseReport";
import { useMemo } from "react";

export const FeatureModal = ({ featureModalKey, isOpen, onClose, unitDetails }) => {
  const MemoizedMemberEventsInferredFromNotes = useMemo(() => <MemberEventsInferredFromNotes isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedMovedOutField = useMemo(() => <MovedOutField isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedAttendance = useMemo(() => <Attendance isOpen={isOpen} onClose={onClose} unitDetails={unitDetails} />, [isOpen, unitDetails]);
  const MemoizedActivityLevel = useMemo(() => <ActivityLevel isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedCallingGroupChats = useMemo(() => <CallingGroupChats isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedMinisteringGroupChats = useMemo(() => <MinisteringGroupChats isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedNewCallingColumns = useMemo(() => <NewCallingColumns isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedSundayProgram = useMemo(() => <SundayProgram isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedRelatedHymns = useMemo(() => <RelatedHymns isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedRoommatesInTheOtherWard = useMemo(() => <RoommatesInTheOtherWard isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedStakeCallings = useMemo(() => <StakeCallings isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedMissionsMap = useMemo(() => <MissionsMap isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedIncomingMemberSurveyResponse = useMemo(() => <IncomingMemberSurveyResponse isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedFlashcardsMatchMode = useMemo(() => <FlashcardsMatchMode isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedFlashcardsLeaderboard = useMemo(() => <FlashcardsLeaderboard isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedSundayProgramHistory = useMemo(() => <SundayProgramHistory isOpen={isOpen} onClose={onClose} />, [isOpen]);
  const MemoizedIncomingMemberSurveyResponseReport = useMemo(() => <IncomingMemberSurveyResponseReport isOpen={isOpen} onClose={onClose} />, [isOpen]);

  if (featureModalKey === "member_events_inferred_from_notes") {
    return MemoizedMemberEventsInferredFromNotes;
  } else if (featureModalKey === "moved_out_field") {
    return MemoizedMovedOutField;
  } else if (featureModalKey === "attendance") {
    return MemoizedAttendance;
  } else if (featureModalKey === "activity_level") {
    return MemoizedActivityLevel;
  } else if (featureModalKey === "calling_group_chats") {
    return MemoizedCallingGroupChats;
  } else if (featureModalKey === "ministering_group_chats") {
    return MemoizedMinisteringGroupChats;
  } else if (featureModalKey === "new_calling_columns") {
    return MemoizedNewCallingColumns;
  } else if (featureModalKey === "sunday_program") {
    return MemoizedSundayProgram;
  } else if (featureModalKey === "related_hymns") {
    return MemoizedRelatedHymns;
  } else if (featureModalKey === "roommates_in_the_other_ward") {
    return MemoizedRoommatesInTheOtherWard;
  } else if (featureModalKey === "stake_callings") {
    return MemoizedStakeCallings;
  } else if (featureModalKey === "missions_map") {
    return MemoizedMissionsMap;
  } else if (featureModalKey === "incoming_member_survey_response") {
    return MemoizedIncomingMemberSurveyResponse;
  } else if (featureModalKey === "flashcards_match_mode") {
    return MemoizedFlashcardsMatchMode;
  } else if (featureModalKey === "sunday_program_history") {
    return MemoizedSundayProgramHistory;
  } else if (featureModalKey === "incoming_member_survey_response_report") {
    return MemoizedIncomingMemberSurveyResponseReport;
  } else if (featureModalKey === "flashcards_leaderboard") {
    return MemoizedFlashcardsLeaderboard;
  }
}
