import React from 'react';

export const SkeletonCommunication = () => {
  return (
    <div style={{
      width: '350px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }}>
      <div style={{ display: "flex", width: "100%", gap: "6px", justifyContent: "flex-start" }}>
        <div style={{
          width: '30px',
          height: '30px',
          backgroundColor: '#EEF0F4',
          borderRadius: '4px'
        }} />
        <div style={{
          width: '30px',
          height: '30px',
          backgroundColor: '#EEF0F4',
          borderRadius: '4px'
        }} />
        <div style={{
          width: '248px',
          height: '30px',
          backgroundColor: '#EEF0F4',
          borderRadius: '6px'
        }} />
        <div style={{
          width: '30px',
          height: '30px',
          backgroundColor: '#EEF0F4',
          borderRadius: '4px'
        }} />
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "4px",
        marginTop: "16px",
        width: '100%'
      }}>
        <div style={{
          width: '100%',
          height: '160px',
          backgroundColor: '#EEF0F4',
          borderRadius: '4px',
          marginBottom: '8px'
        }} />
      </div>
    </div>
  );
}
