import React, { useState } from "react";
import Select from "react-select";
import { NoProfilePicture } from "../shared/NoProfilePicture";
import { formatName } from "../utils";

export const AddPrioritizedCard = ({
  addPrioritized,
  unitDetails,
  membersList,
}) => {
  const [isAdding, setIsAdding] = useState(false);

  const handleAddPrioritized = (option) => {
    addPrioritized(option.value);
    setIsAdding(false);
  };

  if (!isAdding) {
    return (
      <div
        className="add-card" 
        style={{ cursor: "pointer" }}
        onClick={() => setIsAdding(true)}>
          <div
            className="bx bx-plus-circle"
            style={{ fontSize: "24px" }}
            title={unitDetails.language === "es" ? "Agregar miembro" : "Add member"}
          ></div>
      </div>
    );
  }

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "15px",
      textAlign: "left",
      margin: "6px",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      minWidth: "210px",
      left: "50%",
      transform: "translateX(-50%)",
      maxHeight: "240px",
      overflowY: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ display: "flex", alignItems: "center", padding: "2px", cursor: "pointer", borderBottom: "0.5px solid #e0e0e0" }}>
        <div style={{ width: "50px", height: "50px", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {data.member_photo || data.household_photo ? <img src={data.member_photo || data.household_photo} alt={data.label} style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "5px", border: "0.5px solid #B0B5C0" }} /> : <NoProfilePicture />}
        </div>
        <span style={{ flex: 1, textAlign: "left" }}>{formatName(data.label)}</span>
      </div>
    );
  };

  return (
    <div className="add-card" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
      <Select
        onChange={(option) => handleAddPrioritized(option)}
        options={membersList.filter(member => !member.is_prioritized)}
        placeholder={unitDetails.language === "es" ? "Miembro" : "Member"}
        styles={selectStyles}
        components={{ Option: CustomOption }}
      />
    </div>
  );
};
