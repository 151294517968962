import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling
import { useState, useEffect } from "react";

export function formatName(name) {
  return name.split(",").map((part) => part.trim()).reverse().join(" ");
}

export function formatPhoneNumber(phone) {
  const cleaned = phone.replace(/\D/g, "");
  return `+1${cleaned}`;
}

export function formatEnglishBirthdate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: '2-digit', 
    year: 'numeric',
    timeZone: 'UTC'
  });
}

export function formatSpanishBirthdate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('es-ES', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
}

export function formatEnglishDuration(days) {
  if (days < 0) return "0 days";
  if (days < 30) return `${days} day${days === 1 ? '' : 's'}`;
  if (days < 365) {
    const months = Math.floor(days / 30);
    return `${months} month${months === 1 ? '' : 's'}`;
  }
  const years = Math.floor(days / 365);
  return `${years} year${years === 1 ? '' : 's'}`;
}

export function formatSpanishDuration(days) {
  if (days < 0) return "0 días";
  if (days < 30) return `${days} día${days === 1 ? '' : 's'}`;
  if (days < 365) {
    const months = Math.floor(days / 30);
    return `${months} mes${months === 1 ? '' : 'es'}`;
  }
  const years = Math.floor(days / 365);
  return `${years} año${years === 1 ? '' : 's'}`;
}

export const isTextOverflowing = (ref) => {
  if (!ref.current) return false;
  const { scrollWidth, clientWidth } = ref.current;
  return scrollWidth > clientWidth;
};

export const recordInteraction = async (interaction) => {
  try {
    await fetch("/api/record_interaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ interaction }),
    });
  } catch (error) {
    console.error("Error recording interaction", error);
  }
};

export const TooltipIfTruncated = ({ children, elementRef, fullText, direction = "horizontal", width }) => {
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      const el = elementRef.current;
      if (!el) {
        setIsTruncated(false);
        return;
      }

      if (direction === "horizontal") {
        setIsTruncated(el.offsetWidth < el.scrollWidth);
      } else {
        setIsTruncated(el.offsetHeight < el.scrollHeight);
      }
    };

    checkTruncation();

    // Re-check on window resize
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [elementRef, direction]);

  return (
    <Tippy 
      content={fullText} 
      disabled={!isTruncated} 
      delay={[100, 0]} 
      theme="lcrplus"
      maxWidth={width || "none"}
    >
      {children}
    </Tippy>
  );
};
