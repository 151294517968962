export const SkeletonColumns = () => {
  return (
    <div style={{
      display: 'flex',
      gap: '8px',
      margin: '8px',
      width: 'calc(100% - 16px)',
      height: '100vh'
    }}>
      {Array.from({ length: 7 }).map((_, index) => (
        <div
          key={index}
          style={{
            flex: 1,
            minWidth: '168px',
            height: '100%',
            backgroundColor: '#EEF0F4',
            borderRadius: '8px',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <div className="shimmer" />
        </div>
      ))}
    </div>
  );
};