import React from 'react';

const SkeletonButton = () => (
  <div style={{
    width: '120px',
    height: '32px',
    backgroundColor: '#EEF0F4',
    borderRadius: '6px',
    position: 'relative',
    overflow: 'hidden'
  }}>
    <div className="shimmer" />
  </div>
);

const SkeletonContent = () => (
  <div style={{
    width: '350px',
    height: '250px',
    backgroundColor: '#EEF0F4',
    borderRadius: '8px',
    position: 'relative',
    overflow: 'hidden',
    marginTop: '16px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }}>
    <div className="shimmer" />
  </div>
);

export const SkeletonMessageTemplates = () => {
  return (
    <div style={{ padding: '16px' }}>
      <div className="skeleton-template-menu" style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
        <SkeletonButton />
        <SkeletonButton />
        <SkeletonButton />
        <SkeletonButton />
        <SkeletonButton />
      </div>
      <SkeletonContent />
    </div>
  );
};
