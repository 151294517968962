import { formatName, formatEnglishBirthdate, formatSpanishBirthdate } from "../utils";
import React, { useState, useEffect, useRef } from "react";
import "react-day-picker/dist/style.css";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import '../tooltip.css'; // Custom tooltip styling
import { NoProfilePicture } from "../shared/NoProfilePicture";
import { SkeletonModal } from "../skeletons/SkeletonModal";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { formatEnglishDuration } from "../utils";

export const MemberDetails = ({ member, setShowDetails, sessionInfo, unitDetails, setMembersList }) => {
  const initialLoad = useRef(true);
  const [memberDetails, setMemberDetails] = useState({});
  const [notes, setNotes] = useState([]);
  const [name, setName] = useState(null);
  const [wantsCalling, setWantsCalling] = useState(null);
  const [wantsMinisters, setWantsMinisters]  = useState(null);
  const [wantsToMinister, setWantsToMinister] = useState(null);
  const [movedOut, setMovedOut] = useState(null);
  const [doNotContact, setDoNotContact] = useState(null);
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [isPhoneNumberHovered, setIsPhoneNumberHovered] = useState(false);
  const [isEmailHovered, setIsEmailHovered] = useState(false);
  const [isAddressHovered, setIsAddressHovered] = useState(false);
  const [copiedField, setCopiedField] = useState(null);
  const modalRef = useRef(null);
  const [noteText, setNoteText] = useState('');
  const [editedNoteText, setEditedNoteText] = useState('');
  const [activeTab, setActiveTab] = useState("contactAndInfo");
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [roommates, setRoommates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNoteTextareaFocused, setIsNoteTextareaFocused] = useState(false);
  const [isEditedNoteTextareaFocused, setIsEditedNoteTextareaFocused] = useState(false);
  const [incomingMemberSurveyResponse, setIncomingMemberSurveyResponse] = useState({});
  const editTextareaRef = useRef(null);
  const MAX_NOTE_LENGTH = 250;

  async function fetchMemberDetails(selectedMember) {
    try {
      const res = await fetch("/api/member_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: selectedMember.member_id || selectedMember.recommended_id }),
      });
      const data = await res.json();
      const { member_photo, household_photo } = selectedMember;
      setMemberDetails({ member_photo, household_photo, ...data });
      setName(formatName(data.name));
      setWantsCalling(data.wants_calling);
      setWantsMinisters(data.wants_ministers);
      setWantsToMinister(data.wants_to_minister);
      setMovedOut(data.moved_out);
      setDoNotContact(data.do_not_contact);
      await fetch('/api/record_interaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ interaction: 'visited_member_details' }),
      });
    } catch (error) {
      console.error("Error executing query", error);
    }
  }

  async function fetchNotes(selectedMember) {
    try {
      const res = await fetch("/api/list_notes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: selectedMember.member_id || selectedMember.recommended_id }),
      });
      const data = await res.json();
      setNotes(data)
    } catch (error) {
      console.error("Error executing query", error);
    }
  }

  async function fetchImage(url) {
    try {
      const photoRes = await fetch(`/api/image?url=${encodeURIComponent(url)}`);
      if (photoRes.ok) {
        const blob = await photoRes.blob();
        const imageUrl = URL.createObjectURL(blob);
        return imageUrl;
      }
    } catch (error) {
      console.error(`Error fetching photo: ${url}`, error);
    }
    return null;
  }

  async function loadRoommatePhotos(data) {
    const photoPromises = data.flatMap(member => [
      member.member_photo_url ? fetchImage(member.member_photo_url) : null,
      member.household_photo_url ? fetchImage(member.household_photo_url) : null
    ]);
  
    const photoResults = await Promise.all(photoPromises);
  
    return data.map((member, index) => {
      const updatedMember = { ...member };
      if (member.member_photo_url) {
        updatedMember.member_photo = photoResults[index * 2];
      }
      if (member.household_photo_url) {
        updatedMember.household_photo = photoResults[index * 2 + 1];
      }
      return updatedMember;
    });
  }

  async function fetchRoommates(selectedMember) {
    const res = await fetch("/api/list_roommates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ memberId: selectedMember.member_id || selectedMember.recommended_id }),
    });
    const data = await res.json();
    const roommatesWithPhotos = await loadRoommatePhotos(data);
    setRoommates(roommatesWithPhotos);
  }

  async function fetchIncomingMemberSurveyResponse(selectedMember) {
    const res = await fetch("/api/incoming_ward_member/survey_response", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ memberId: selectedMember.member_id || selectedMember.recommended_id }),
    });
    const data = await res.json();
    setIncomingMemberSurveyResponse(data);
  }

  if (initialLoad.current) {
    const loadData = async () => {
      setIsLoading(true);
      await fetchMemberDetails(member);
      if (["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel)) {
        await fetchIncomingMemberSurveyResponse(member);
        await fetchRoommates(member);
        await fetchNotes(member);
      }
      setIsLoading(false);
    };

    loadData();
    initialLoad.current = false;
  }

  const handleRoommateClick = async (roommate) => {
    setIsLoading(true);
    setActiveTab("contactAndInfo");
    await fetchMemberDetails(roommate);
    if (["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel)) {
      await fetchIncomingMemberSurveyResponse(roommate);
      await fetchRoommates(roommate);
      await fetchNotes(roommate);
    }
    setIsLoading(false);
  };

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = memberDetails.member_photo;
  let householdPhotoSrc = memberDetails.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  }

  function copyToClipboard(text, fieldName) {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(fieldName);
      setTimeout(() => setCopiedField(null), 1000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setMembersList((prevMembers) =>
          prevMembers.map((item) => 
            item.value === memberDetails.id
              ? { ...item, wants_calling: wantsCalling, wants_ministers: wantsMinisters, wants_to_minister: wantsToMinister, moved_out: movedOut, do_not_contact: doNotContact, notes: notes }
              : item
          )
        );
        setShowDetails(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDetails, wantsCalling, wantsMinisters, wantsToMinister, movedOut, doNotContact, notes]);

  async function createNote(note) {
    try {
      const res = await fetch("/api/create_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, note: note, memberName: name }),
      });
      const data = await res.json();
      setNotes((prevNotes) => [...prevNotes, data]);
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function editNote(noteId, note) {
    try {
      await fetch("/api/edit_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ noteId, note }),
      });
      setNotes((prevNotes) => prevNotes.map(n => n.id === noteId ? { ...n, note: note, is_edited: true } : n));
    } catch (error) {
      console.error("Error executing query", error);
    }
  }

  async function deleteNote(noteId) {
    try {
      await fetch("/api/delete_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ noteId: noteId }),
      });
      setNotes((prevNotes) => prevNotes.filter(note => note.id !== noteId));
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateWantsCalling(newValue) {
    try {
      setWantsCalling(newValue);
      await fetch("/api/update_wants_calling", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, wantsCalling: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateWantsMinisters(newValue) {
    try {
      setWantsMinisters(newValue);
      await fetch("/api/update_wants_ministers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, wantsMinisters: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateWantsToMinister(newValue) {
    try {
      setWantsToMinister(newValue);
      await fetch("/api/update_wants_to_minister", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, wantsToMinister: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateMovedOut(newValue) {
    try {
      setMovedOut(newValue);
      await fetch("/api/update_moved_out", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, movedOut: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateDoNotContact(newValue) {
    try {
      setDoNotContact(newValue);
      await fetch("/api/update_do_not_contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, doNotContact: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  const handleSave = () => {
    createNote(noteText);
    setNoteText('');
  };

  const handleSaveEdit = () => {
    editNote(editingNoteId, editedNoteText);
    setEditedNoteText('');
    setIsEditingNote(false);
  };

  const handleCancelEdit = () => {
    setEditedNoteText('');
    setIsEditingNote(false);
    setEditingNoteId(null);
  };

  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
  };

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const WantsCallingTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>Quiere un llamamiento</strong> está marcado por defecto.<br><br>
      Desmarque esta casilla si la persona no quiere un llamamiento.<br><br>
      Si se desmarca, la persona no aparecerá en la lista de personas recomendadas para un llamamiento.
    </div>
  ` : `
    <div>
      <strong>Wants a calling</strong> defaults to checked.<br><br>
      Uncheck this box if the individual does not want a calling.<br><br>
      If unchecked, the individual will not show up in the list of people to recommend for a calling.
    </div>
  `;

  const WantsToMinisterTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>Quiere ministrar</strong> está marcado por defecto.<br><br>
      Desmarque esta casilla si la persona no quiere una asignación de ministración.<br><br>
      Si se desmarca, la persona no aparecerá en la lista de personas para poner en una compañía de ministración.
    </div>
  ` : `
    <div>
      <strong>Wants to minister</strong> defaults to checked.<br><br>
      Uncheck this box if the individual does not want a ministering assignment.<br><br>
      If unchecked, the individual will not show up in the list of people to put in a ministering companionship.
    </div>
  `;

  const WantsMinistersTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>Quiere ser ministrado</strong> está marcado por defecto.<br><br>
      Desmarque esta casilla si la persona no quiere ser ministrada.<br><br>
      Si se desmarca, la persona no aparecerá en la lista de personas para agregar como asignación a una compañía de ministración.
    </div>
  ` : `
    <div>
      <strong>Wants ministers</strong> defaults to checked.<br><br>
      Uncheck this box if the individual does not want to be ministered to.<br><br>
      If unchecked, the individual will not show up in the list of people to add as an assignment for a ministering companionship.
    </div>
  `;

  const MovedOutTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>No contactar</strong> está desmarcado por defecto.<br><br>
      Cuando se marca <strong>No contactar</strong>, se asume que el miembro está inactivo y no quiere un llamamiento ni quiere ser parte de la ministración, por lo que todas las demás casillas de verificación estarán deshabilitadas y configuradas con los valores asumidos.
    </div>
  ` : `
    <div>
      <strong>Moved out</strong> defaults to unchecked.<br><br>
      When <strong>Moved out</strong> is checked, the calling and ministering boxes are unchecked and disabled.
    </div>
  `;

  const DoNotContactTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>No contactar</strong> está desmarcado por defecto.<br><br>
      Cuando se marca <strong>No contactar</strong>, se asume que el miembro está inactivo y no quiere un llamamiento ni quiere ser parte de la ministración, por lo que todas las demás casillas de verificación estarán deshabilitadas y configuradas con los valores asumidos.
    </div>
  ` : `
    <div>
      <strong>Do not contact</strong> defaults to unchecked.<br><br>
      When <strong>Do not contact</strong> is checked, it is assumed that the member does not want a calling or want to be part of ministering, so the other checkboxes will be unchecked and disabled.
    </div>
  `;
  
  return (
    <>
      <div className="modal-overlay"></div>
      {isLoading ? <SkeletonModal /> : (
      <div className="modal" ref={modalRef}>
        <div
          className="member-card"
          style={{
            border: `3px solid #B0B5C0`,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{ cursor: "pointer", position: "absolute", top: "0px", right: "-2px", zIndex: 20000 }}
            onClick={() => {
              setMembersList((prevMembers) =>
                prevMembers.map((item) =>
                  item.value === memberDetails.id
                    ? { ...item, wants_calling: wantsCalling, wants_ministers: wantsMinisters, wants_to_minister: wantsToMinister, do_not_contact: doNotContact }
                    : item
                )
              );
              setShowDetails(false)
            }}
          >
            <i className="bx bx-x" style={{ fontSize: "22px" }}></i>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "15px",
            }}
          >
            {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
              <button
                className="photo-toggle bx bx-chevron-right"
                onClick={togglePhoto}
              />
            )}
            <div
              style={{
                height: "380px",
                width: "380px",
                overflow: "hidden",
              }}
            >
              {currentPhotoSrc ? (
                <img 
                  src={currentPhotoSrc} 
                  alt={name} 
                  style={{
                    width: "380px",
                    height: "380px",
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <NoProfilePicture />
              )}
            </div>
            {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
              <button
                className="photo-toggle bx bx-chevron-left"
                onClick={togglePhoto}
              />
            )}
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "10px",
          }}>
            <i className={memberDetails.gender === 'F' ? "bx bx-female" : "bx bx-male"} style={{ fontSize: "28px" }}></i>
            <div style={{ fontSize: "16px" }}>
              <strong>{name}{memberDetails.age ? ` (${memberDetails.age})`: ""}</strong>
            </div>
          </div>
          {sessionInfo.accessLevel === "WARD_MISSION" && (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <div onClick={() => setActiveTab("contactAndInfo")} className={`menu-item ${activeTab === "contactAndInfo" ? "selected" : ""}`}>
                {unitDetails.language === "es" ? "Contacto y información" : "Contact & info"}
              </div>
              <div onClick={() => setActiveTab("roommates")} className={`menu-item ${activeTab === "roommates" ? "selected" : ""}`}>
                {unitDetails.language === "es" ? "Compañeros de cuarto" : "Roommates"}{roommates.length > 0 ? ` (${roommates.length})` : ""}
              </div>
            </div>
          )}
          {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel) && (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <div onClick={() => setActiveTab("contactAndInfo")} className={`menu-item ${activeTab === "contactAndInfo" ? "selected" : ""}`}>
                {unitDetails.language === "es" ? "Contacto y información" : "Contact & info"}
              </div>
              <div onClick={() => setActiveTab("roommates")} className={`menu-item ${activeTab === "roommates" ? "selected" : ""}`}>
                {unitDetails.language === "es" ? "Compañeros de cuarto" : "Roommates"}{roommates.length > 0 ? ` (${roommates.length})` : ""}
              </div>
              <div
                onClick={() => setActiveTab("notes")}
                className={`menu-item ${activeTab === "notes" ? "selected" : ""}`}
              >
                {unitDetails.language === "es" ? "Notas" : "Notes"}{notes.length > 0 ? ` (${notes.length})` : ""}
              </div>
              <div onClick={() => setActiveTab("settings")} className={`menu-item ${activeTab === "settings" ? "selected" : ""}`}>
                {unitDetails.language === "es" ? "Configuración" : "Settings"}
              </div>
            </div>
          )}
          <hr style={{ width: "100%", border: "0.25px solid #e0e0e0", padding: "0px" }} />
          {activeTab === "contactAndInfo" && (
            <div style={{ margin: "10px", fontSize: "14px" }}>
              {memberDetails.phone_number && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Phone number:</span>
                  <div
                    onClick={() => copyToClipboard(memberDetails.phone_number, "phone_number")}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isPhoneNumberHovered && copiedField !== "phone_number" ? "#f0f0f0" : "transparent", 
                      fontStyle: copiedField === "phone_number" ? "italic" : "normal"
                    }}
                    onMouseEnter={() => setIsPhoneNumberHovered(true)} 
                    onMouseLeave={() => setIsPhoneNumberHovered(false)}
                  >
                    {copiedField === "phone_number" ? (unitDetails.language === "es" ? "¡Copiado!" : "Copied!") : memberDetails.phone_number}
                  </div>
                </div>
              )}
              {memberDetails.email && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Email:</span>
                  <div
                    onClick={() => copyToClipboard(memberDetails.email, "email")}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isEmailHovered && copiedField !== "email" ? "#f0f0f0" : "transparent", 
                      fontStyle: copiedField === "email" ? "italic" : "normal"
                    }}
                    onMouseEnter={() => setIsEmailHovered(true)} 
                    onMouseLeave={() => setIsEmailHovered(false)}
                  >
                    {copiedField === "email" ? (unitDetails.language === "es" ? "¡Copiado!" : "Copied!") : memberDetails.email}
                  </div>
                </div>
              )}
              {memberDetails.address_line_1 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Address:</span>
                  <div
                    onClick={() => copyToClipboard([memberDetails.address_line_1, memberDetails.address_line_2, memberDetails.address_line_3].filter(Boolean).join(", "), "address")}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isAddressHovered && copiedField !== "address" ? "#f0f0f0" : "transparent", 
                      fontStyle: copiedField === "address" ? "italic" : "normal",
                      textAlign: "left",
                      wordBreak: "break-word"
                    }}
                    onMouseEnter={() => setIsAddressHovered(true)} 
                    onMouseLeave={() => setIsAddressHovered(false)}
                  >
                    <div style={{ maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                      {copiedField === "address" ? (unitDetails.language === "es" ? "¡Copiado!" : "Copied!") : [memberDetails.address_line_1, memberDetails.address_line_2, memberDetails.address_line_3].filter(Boolean).join(", ")}
                    </div>
                  </div>
                </div>
              )}
              {memberDetails.birthdate && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Birth date:</span> {unitDetails.language === "es" ? formatSpanishBirthdate(memberDetails.birthdate) : formatEnglishBirthdate(memberDetails.birthdate)}
                </div>
              )}
              {memberDetails.birth_place && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Birthplace:</span> {memberDetails.birth_place}
                </div>
              )}
              {memberDetails.birth_country && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Birth country:</span> {memberDetails.birth_country}
                </div>
              )}
              {memberDetails.endowment_date && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Endowment date:</span> {unitDetails.language === "es" ? formatSpanishBirthdate(memberDetails.endowment_date) : formatEnglishBirthdate(memberDetails.endowment_date)}
                </div>
              )}
              {memberDetails.mission_country && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Mission country:</span> {memberDetails.mission_country}
                </div>
              )}
              {memberDetails.mission_language && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Mission language:</span> {memberDetails.mission_language}
                </div>
              )}
              {memberDetails.moved_in_date && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Move-in date:</span> {unitDetails.language === "es" ? formatSpanishBirthdate(memberDetails.moved_in_date) : formatEnglishBirthdate(memberDetails.moved_in_date)}
                </div>
              )}
              {memberDetails.time_in_ward && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Time in ward:</span> {formatEnglishDuration(memberDetails.time_in_ward)}
                </div>
              )}
              {memberDetails.previous_unit_name && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Previous ward:</span> {memberDetails.previous_unit_name}
                </div>
              )}
              {Object.entries(incomingMemberSurveyResponse).map(([key, value]) => (
                <div
                  key={key}
                  style={{
                    display: "flex", 
                    alignItems: "flex-start",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                    textAlign: "left",
                    float: "left",
                    width: "100%"
                  }}
                >
                  <span style={{ 
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    flexShrink: 0
                  }}>{key}{key.endsWith('?') ? '' : ':'}</span>
                  <span style={{
                    wordBreak: "break-word"
                  }}>{value}</span>
                </div>
              ))}
            </div>
          )}
          {activeTab === "roommates" && (
            <>
              {memberDetails.latitude && memberDetails.longitude && roommates.length > 0 && (
                <div style={{ margin: "10px", display: "flex", flexDirection: "column", gap: "10px" }}>
                  {roommates
                    .sort((a, b) => {
                      if (a.unit_id === unitDetails.id && b.unit_id !== unitDetails.id) return -1;
                      if (a.unit_id !== unitDetails.id && b.unit_id === unitDetails.id) return 1;
                      return 0;
                    })
                    .map(roommate => (
                      <div 
                        key={roommate.id} 
                        style={{ 
                          display: "flex", 
                          alignItems: "center", 
                          padding: "2px",
                          fontSize: '16px', 
                          gap: "10px", 
                          cursor: roommate.unit_id === unitDetails.id ? "pointer" : "default" 
                        }}
                        onClick={roommate.unit_id === unitDetails.id ? () => handleRoommateClick(roommate) : undefined}
                      >
                        <div style={{ marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {roommate.member_photo || roommate.household_photo ?
                            <img
                              src={roommate.member_photo || roommate.household_photo}
                              alt={roommate.label}
                              style={{ width: "35px", height: "35px", borderRadius: "50%", border: "0.5px solid #B0B5C0", objectFit: "cover" }}
                            /> : <NoProfilePicture styles={{ width: "35px", height: "35px" }} rounded={true} />
                          }
                        </div>
                        <span style={{ fontWeight: 600 }}>{formatName(roommate.label)}{roommate.unit_id === unitDetails.id ? "" : <span style={{ fontWeight: 'normal', fontStyle: 'italic', fontSize: '14px' }}>{` (${sessionInfo.sisterUnitName})`}</span>}</span>
                      </div>
                    ))
                  }
                  {!unitDetails.sister_unit_id && (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', fontStyle: "italic", gap: "4px", marginTop: "10px" }}>
                      <i className="bx bx-info-circle"></i>
                      <span>{unitDetails.language === "es" ? "Nota: solo se muestran los compañeros de cuarto en el barrio." : "Note: only roommates in the ward are shown."}</span>
                    </div>
                  )}
                </div>
              )}
              {!memberDetails.latitude && !memberDetails.longitude && (
                <div style={{ textAlign: 'center', fontSize: '14px', fontStyle: "italic", margin: "10px", width: "95%" }}>
                  <span>{unitDetails.language === "es" ? "Dirección no disponible" : "Address not available"}</span>
                </div>
              )}
              {memberDetails.latitude && memberDetails.longitude && roommates.length === 0 && !unitDetails.sister_unit_id && (
                <div style={{ textAlign: 'center', fontSize: '14px', fontStyle: "italic", margin: "10px", width: "95%" }}>
                  <span>{unitDetails.language === "es" ? "No hay compañeros de cuarto en el barrio" : "No roommates in the ward"}</span>
                </div>
              )}
              {memberDetails.latitude && memberDetails.longitude && roommates.length === 0 && unitDetails.sister_unit_id && (
                <div style={{ textAlign: 'center', fontSize: '14px', fontStyle: "italic", margin: "10px", width: "95%" }}>
                  <span>{unitDetails.language === "es" ? "No hay compañeros de cuarto en el barrio o en la hermana unidad" : "No roommates in the ward or sister ward"}</span>
                </div>
              )}
            </>
          )}
          {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel) && activeTab === "notes" && (
            <>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '10px',
                boxSizing: 'border-box'
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  boxSizing: 'border-box',
                  borderRadius: '8px',
                  border: isNoteTextareaFocused ? '1px solid #99A0AE' : '1px solid #E1E4EA'
                }}>
                  <textarea
                    autoFocus={true}
                    value={noteText}
                    onChange={(e) => {
                      setNoteText(e.target.value);
                      adjustTextareaHeight(e.target);
                    }}
                    onFocus={() => setIsNoteTextareaFocused(true)}
                    onBlur={() => setIsNoteTextareaFocused(false)}
                    placeholder={unitDetails.language === "es" ? "Escribir una nota..." : "Write a note..."}
                    rows="1"
                    maxLength={MAX_NOTE_LENGTH}
                    style={{
                      border: 'none',
                      width: "100%",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      resize: "none",
                      boxSizing: 'border-box',
                      padding: '8px',
                      outline: 'none',
                      backgroundColor: 'transparent',
                      overflow: 'hidden',
                    }}
                  />
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '4px',
                    backgroundColor: '#F6F7F8',
                    border: 'none',
                    borderRadius: '0 0 8px 8px'
                  }}>
                    <button onClick={handleSave} className="cta" disabled={noteText.length === 0}>
                      {unitDetails.language === "es" ? "Guardar" : "Save"}
                    </button>
                  </div>
                </div>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                padding: '0 10px',
                boxSizing: 'border-box'
              }}>
                {notes.map((note, index) => (
                  <div key={index} className="note-item" style={{ padding: '10px', width: '100%', boxSizing: 'border-box' }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                      {isEditingNote && editingNoteId === note.id ? (
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          boxSizing: 'border-box',
                          borderRadius: '8px',
                          border: isEditedNoteTextareaFocused ? '1px solid #99A0AE' : '1px solid #E1E4EA'
                        }}>
                          <textarea
                            ref={editTextareaRef}
                            value={editedNoteText}
                            onChange={(e) => {
                              setEditedNoteText(e.target.value);
                              adjustTextareaHeight(e.target);
                            }}
                            onFocus={() => setIsEditedNoteTextareaFocused(true)}
                            onBlur={() => setIsEditedNoteTextareaFocused(false)}
                            placeholder={unitDetails.language === "es" ? "Escribir una nota..." : "Write a note..."}
                            rows="1"
                            style={{
                              border: "none",
                              width: "100%",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              resize: "none",
                              boxSizing: 'border-box',
                              padding: '8px',
                              outline: 'none',
                              backgroundColor: 'transparent',
                              overflow: 'hidden',
                            }}
                          />
                          <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '8px',
                            padding: '4px',
                            backgroundColor: '#F6F7F8',
                            border: 'none',
                            borderRadius: '0 0 8px 8px'
                          }}>
                            <button 
                              onClick={handleCancelEdit} 
                              className="cta"
                            >
                              {unitDetails.language === "es" ? "Cancelar" : "Cancel"}
                            </button>
                            <button 
                              onClick={handleSaveEdit} 
                              className="cta" 
                              disabled={editedNoteText.length === 0}
                            >
                              {unitDetails.language === "es" ? "Guardar" : "Save"}
                            </button>
                          </div>
                        </div>
                      ) : 
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                          <div>{note.note}</div>
                          <small style={{ fontStyle: 'italic' }}>
                            {note.author} &#8212; {new Date(note.created_date).toLocaleDateString()} {note.is_edited && '(Edited)'}
                          </small>
                        </div>
                      }
                    </div>
                    {note.is_author && (
                      <DropdownMenu>
                        <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-5.5px", zIndex: 1000}} asChild>
                          <MoreVertRoundedIcon style={{ fontSize: "20px", color: "#0E121B" }}></MoreVertRoundedIcon>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          className="dropdown-content"
                          align="end"
                          side="bottom"      
                          style={{zIndex: 1000}}
                          onCloseAutoFocus={() => {
                            if (editTextareaRef.current) {
                              editTextareaRef.current.focus();
                              editTextareaRef.current.setSelectionRange(editedNoteText.length, editedNoteText.length);
                              adjustTextareaHeight(editTextareaRef.current);
                            }
                          }}
                        >
                          <DropdownMenuRadioGroup>
                            <DropdownMenuRadioItem
                              className="dropdown-item"
                              onClick={() => {
                                setIsEditingNote(true);
                                setEditingNoteId(note.id);
                                setEditedNoteText(note.note);
                                setIsEditedNoteTextareaFocused(true);
                              }}
                              style={{ fontSize: "16px" }}
                            >
                              <EditRoundedIcon style={{ marginRight: "5px", fontSize: "16px" }}></EditRoundedIcon>
                              Edit
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem
                              className="dropdown-item"
                              onClick={() => deleteNote(note.id)}
                              style={{ fontSize: "16px" }}
                            >
                              <DeleteRoundedIcon style={{ marginRight: "5px", fontSize: "16px" }}></DeleteRoundedIcon>
                              Delete
                            </DropdownMenuRadioItem>
                          </DropdownMenuRadioGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    )}
                  </div>
                ))}
                <div style={{ display: "flex", width: "100%", fontSize: '12px', fontStyle: "italic", gap: "4px", marginTop: "10px", marginBottom: "10px" }}>
                  <i className="bx bx-info-circle"></i>
                  <span>Notes are visible to Bishropic and EQ/RS presidencies only.</span>
                </div>
              </div>
            </>
          )}
          {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel) && activeTab === "settings" && (
            <div style={{ margin: "8px", width: "calc(100% - 16px)" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "4px",
                  opacity: (movedOut || doNotContact) ? 0.65 : 1,
                  color: (movedOut || doNotContact) ? "#808080" : "inherit",
                }}
              >
                <i
                  className={wantsCalling ? "bx bxs-check-square" : "bx bx-square"}
                  style={{ 
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: (movedOut || doNotContact) ? "default" : "pointer",
                    pointerEvents: (movedOut || doNotContact) ? "none" : "auto",
                  }}
                  onClick={() => updateWantsCalling(!wantsCalling)}
                ></i>
                {unitDetails.language === "es" ? "Quiere un llamamiento" : "Wants a calling"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: WantsCallingTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "4px",
                  opacity: (movedOut || doNotContact) ? 0.65 : 1,
                  color: (movedOut || doNotContact) ? "#808080" : "inherit",
                }}
              >
                <i
                  className={wantsToMinister ? "bx bxs-check-square" : "bx bx-square"}
                  style={{ 
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: (movedOut || doNotContact) ? "default" : "pointer",
                    pointerEvents: (movedOut || doNotContact) ? "none" : "auto",
                  }}
                  onClick={() => updateWantsToMinister(!wantsToMinister)}
                ></i>
                {unitDetails.language === "es" ? "Quiere ministrar" : "Wants to minister"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: WantsToMinisterTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "4px",
                  opacity: (movedOut || doNotContact) ? 0.65 : 1,
                  color: (movedOut || doNotContact) ? "#808080" : "inherit",
                }}
              >
                <i
                  className={wantsMinisters ? "bx bxs-check-square" : "bx bx-square"}
                  style={{ 
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: (movedOut || doNotContact) ? "default" : "pointer",
                    pointerEvents: (movedOut || doNotContact) ? "none" : "auto",
                  }}
                  onClick={() => updateWantsMinisters(!wantsMinisters)}
                ></i>
                {unitDetails.language === "es" ? "Quiere ministros" : "Wants ministers"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: WantsMinistersTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
              <div style={{ height: '1px', background: 'rgba(0,0,0,0.1)', margin: '8px 0' }} />
              <div style={{ display: "flex", alignItems: "center", padding: "4px", gap: "5px" }}>
                <i
                  className={movedOut ? "bx bxs-check-square" : "bx bx-square"}
                  style={{
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    updateMovedOut(!movedOut);
                    if (!movedOut) {
                      updateWantsCalling(false);
                      updateWantsToMinister(false);
                      updateWantsMinisters(false);
                    }
                  }}
                ></i>
                {unitDetails.language === "es" ? "Migrado" : "Moved out"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: MovedOutTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
              <div style={{ display: "flex", alignItems: "center", padding: "4px", gap: "5px" }}>
                <i
                  className={doNotContact ? "bx bxs-check-square" : "bx bx-square"}
                  style={{
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    updateDoNotContact(!doNotContact);
                    if (!doNotContact) {
                      updateWantsCalling(false);
                      updateWantsToMinister(false);
                      updateWantsMinisters(false);
                    }
                  }}
                ></i>
                {unitDetails.language === "es" ? "No contactar" : "Do not contact"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: DoNotContactTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
            </div>
          )}
        </div>
      </div>
      )}
    </>
  );
};
