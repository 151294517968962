import React, { useState, useRef } from "react";
import 'tippy.js/dist/tippy.css';
import '../tooltip.css';
import * as Tabs from '@radix-ui/react-tabs';
import { Menu } from "./Menu";
import { LogoIcon } from "../logos/LogoIcon";

export const Header = ({
  activeTab,
  overallNumbers,
  handleTabSelection,
  zoomedOut,
  setZoomedOut,
  unitDetails,
  sessionInfo,
  autoGenerateMinistering,
  resetMinistering,
}) => {
  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <div className="header">
        <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
          <>
            <button
              onClick={() => setIsDrawerOpen(true)}
              style={{ backgroundColor: "transparent", border: "none", outline: "none", marginTop: "2px", marginRight: "10px", marginLeft: "10px" }}
            >
              <i className="bx bx-menu" style={{ cursor: "pointer", padding: "2px", fontSize: "24px", borderRadius: "4px", boxShadow: "0 -2px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)" }}></i>
            </button>
            <Menu sessionInfo={sessionInfo} unitDetails={unitDetails} activeTab={activeTab} handleTabSelection={handleTabSelection} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
          </>
          <div style={{ height: "26px", width: "26px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <LogoIcon scaleFactor={0.1} />
          </div>
          {activeTab === "home" && (<h1 className="welcome">{unitDetails.language === "es" ? "Llamamientos pendientes" : "Pending callings"}</h1>)}
          {activeTab === "myOrganization" && (<h1 className="welcome">{unitDetails.language === "es" ? "Mi organización" : "My organization"}</h1>)}
          {activeTab === "callingsByOrganization" && (<h1 className="welcome">{unitDetails.language === "es" ? "Llamamientos por organización" : "Callings by organization"}</h1>)}
          {activeTab === "memberReports" && (<h1 className="welcome">{unitDetails.language === "es" ? "Informes de miembros" : "Member reports"}</h1>)}
          {activeTab === "communication" && (<h1 className="welcome">{unitDetails.language === "es" ? "Comunicación" : "Communication"}</h1>)}
          {activeTab === "messageTemplates" && (<h1 className="welcome">{unitDetails.language === "es" ? "Plantillas de mensajes" : "Message templates"}</h1>)}
          {activeTab === "ministering" && (<h1 className="welcome">{unitDetails.language === "es" ? "Ministerio" : "Ministering"}</h1>)}
          {activeTab === "roommates" && (<h1 className="welcome">{unitDetails.language === "es" ? "Compañeros de cuarto" : "Roommates"}</h1>)}
          {activeTab === "flashcards" && (<h1 className="welcome">{unitDetails.language === "es" ? "Tarjetas de memoria" : "Flashcards"}</h1>)}
          {activeTab === "bishopInterviews" && (<h1 className="welcome">{unitDetails.language === "es" ? "Entrevistas obispo" : "Bishop interviews"}</h1>)}
          {activeTab === "sundayProgram" && (<h1 className="welcome">{unitDetails.language === "es" ? "Programa dominical" : "Sunday program"}</h1>)}
        </div>
        {overallNumbers && (
          <div className="overall-container">
            {activeTab === "callingsByOrganization" && (
              <>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "# CON LLAMAMIENTOS" : "MEMBERS WITH CALLINGS"}</div>
                  <div className="overall-number">{overallNumbers["members_with_callings_count"]}</div>
                </div>
                <div className="overall-box">
                  <div className="overall-label">
                    {unitDetails.language === "es" ? "MIEMBROS TOTALES" : "TOTAL MEMBERS"}
                  </div>
                  <div className="overall-number">{overallNumbers["total_members_count"]}</div>
                </div>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "% CON LLAMAMIENTOS" : "PERCENT WITH CALLINGS"}</div>
                  <div className="overall-number">{(overallNumbers["members_with_callings_count"] / overallNumbers["total_members_count"] * 100).toFixed()}%</div>
                </div>
              </>
            )}
            {/* {activeTab === "ministering" && ["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel) && (
              <>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "COMPAÑERISMOS" : "COMPANIONSHIPS"}</div>
                  <div className="overall-number">{overallNumbers["ministering_companionships_count"]}</div>
                </div>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "COMPANIONES" : "COMPANIONS"}</div>
                  <div className="overall-number">{overallNumbers["ministering_companions_count"]}</div>
                </div>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "ASIGNACIONES" : "ASSIGNMENTS"}</div>
                  <div className="overall-number">{overallNumbers["ministering_assignments_count"]}</div>
                </div>
              </>
            )} */}
            {activeTab === "flashcards" && sessionInfo.accessLevel === "BISHOPRIC" && (
              <>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "MIEMBROS CON FOTOS" : "MEMBERS WITH PHOTO"}</div>
                  <div className="overall-number">{overallNumbers["members_with_photos_count"]}</div>
                </div>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "MIEMBROS SIN FOTOS" : "MEMBERS W/OUT PHOTO"}</div>
                  <div className="overall-number">{overallNumbers["members_without_photos_count"]}</div>
                </div>
              </>
            )}
            {activeTab === "flashcards" && sessionInfo.accessLevel === "EQ_PRESIDENCY" && (
              <>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "HERMANOS CON FOTOS" : "BROTHERS WITH PHOTO"}</div>
                  <div className="overall-number">{overallNumbers["brothers_with_photos_count"]}</div>
                </div>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "HERMANOS SIN FOTOS" : "BROTHERS W/OUT PHOTO"}</div>
                  <div className="overall-number">{overallNumbers["brothers_without_photos_count"]}</div>
                </div>
              </>
            )}
            {activeTab === "flashcards" && sessionInfo.accessLevel === "RS_PRESIDENCY" && (
              <>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "HERMANAS CON FOTOS" : "SISTERS WITH PHOTO"}</div>
                  <div className="overall-number">{overallNumbers["sisters_with_photos_count"]}</div>
                </div>
                <div className="overall-box">
                  <div className="overall-label">{unitDetails.language === "es" ? "HERMANAS SIN FOTOS" : "SISTERS W/OUT PHOTO"}</div>
                  <div className="overall-number">{overallNumbers["sisters_without_photos_count"]}</div>
                </div>
              </>
            )}
            {/* {sessionInfo.accessLevel === "PRESIDENCY" && activeTab === "ministering" && unitDetails.id === 1 && (
              <>
                <button onClick={() => autoGenerateMinistering()} className="cta">
                  {unitDetails.language === "es" ? "Autogenerar" : "Auto-generate"}
                  <i style={{ marginLeft: '5px', fontSize: '18px', position: 'relative', top: '3px' }} className="bx bxs-bolt"></i>
                </button>
                <button onClick={() => resetMinistering()} className="cta">
                  {unitDetails.language === "es" ? "Restablecer" : "Reset"}
                  <i style={{ marginLeft: '5px', fontSize: '18px', position: 'relative' }} className="bx bx-reset"></i>
                </button>
              </>
            )} */}
            {((sessionInfo.accessLevel === "BISHOPRIC" && ["home", "callingsByOrganization"].includes(activeTab)) || (["EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel) && activeTab === "ministering")) && (
              <Tabs.Root defaultValue={zoomedOut} style={{ display: 'flex', gap: '8px' }}>
                <Tabs.List style={{ display: 'flex', backgroundColor: "#F6F7F8", borderRadius: '6px', height: "36px" }}>
                  <Tabs.Trigger
                    value={true}
                    onClick={() => setZoomedOut(true)}
                    style={{
                      border: 'none',
                      borderRadius: '4px',
                      color: zoomedOut ? '#0E121B' : '#99A0AE',
                      backgroundColor: zoomedOut ? 'white' : '#F6F7F8',
                      cursor: 'pointer',
                      margin: '4px',
                      transition: 'background-color 0.2s',
                      fontSize: '14px',
                      fontWeight: '500',
                      width: '132px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      boxShadow: zoomedOut ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
                    }}
                  >
                    <i style={{ color: "#99A0AE" }} className="bx bx-show hide-on-small-screens"></i>
                    <strong>{unitDetails.language === "es" ? "Ver" : "View"}</strong>
                  </Tabs.Trigger>
                  <Tabs.Trigger
                    value={false}
                    onClick={() => setZoomedOut(false)}
                    style={{
                      border: 'none',
                      borderRadius: '4px',
                      color: zoomedOut ? '#99A0AE' : '#0E121B',
                      backgroundColor: zoomedOut ? '#F6F7F8' : 'white',
                      cursor: 'pointer',
                      margin: '4px',
                      transition: 'background-color 0.2s',
                      fontSize: '14px',
                      fontWeight: '500',
                      width: '132px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      boxShadow: zoomedOut ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <i style={{ color: "#99A0AE" }} className="bx bx-pencil hide-on-small-screens"></i>
                    <strong>{unitDetails.language === "es" ? "Editar" : "Edit"}</strong>
                  </Tabs.Trigger>
                </Tabs.List>
              </Tabs.Root>
            )}
          </div>
        )}
      </div>
      {showModal && (
        <>
          <div className="modal-overlay"></div>
          <div className="modal" ref={modalRef}>
            Algorithm parameters:
              * No roommate assignments. You won't minister to your roommate, and your roommate won't minister to you.
              * No circular assignments: those you minister to will not minister to you.
              * The president and counselors will not be included. However, the secretary will be included.
              * The algorithm will try to create groups of 3 (e.g., 3 companions minister to 3 assignments). If the total number is not divisible by 3, it will create groups of 2.
            <button onClick={() => autoGenerateMinistering()} className="cta">
              {unitDetails.language === "es" ? "Autogenerar" : "Confirm"}
              <i style={{ marginLeft: '5px', fontSize: '18px', position: 'relative', top: '3px' }} className="bx bxs-bolt"></i>
            </button>
          </div>
        </>
      )}
    </>
  )
}

// For later use:)
// <span style={{ marginLeft: "65px", fontSize: "12px", fontWeight: "bold", color: "white", backgroundColor: "#74B7FF", borderRadius: "4px", border: "2px", paddingTop: "1px", paddingBottom: "1px", paddingLeft: "3px", paddingRight: "3px" }}>
// BETA
// </span>
{/* <span style={{ marginLeft: "22px", fontSize: "12px", backgroundColor: "#abf2b8", borderRadius: "4px", border: "2px", padding: "1px" }}>
{unitDetails.language === "es" ? "PRÓXIMAMENTE" : "COMING SOON"}
</span> */}