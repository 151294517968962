const SkeletonBlock = ({ width, height, marginTop = "8px" }) => (
  <div style={{
    width,
    height,
    backgroundColor: "#EEF0F4",
    borderRadius: "4px",
    position: "relative",
    overflow: "hidden",
    marginTop
  }}>
    <div className="shimmer" />
  </div>
);

export const SkeletonSundayProgram = () => {
  return (
    <div className="container" style={{ margin: "0", display: "flex", gap: "20px", justifyContent: "space-between", flexWrap: "wrap" }}>
      <div>
        <SkeletonBlock width="200px" height="38px" marginTop="0" /> {/* Date */}
        <SkeletonBlock width="300px" height="38px" />

        <SkeletonBlock width="200px" height="38px" marginTop="20px" /> {/* Presiding and Conducting */}
        <SkeletonBlock width="300px" height="38px" />
        <SkeletonBlock width="300px" height="38px" />

        <SkeletonBlock width="200px" height="38px" marginTop="20px" /> {/* High Councilor */}
        <SkeletonBlock width="300px" height="38px" />
        
        <SkeletonBlock width="200px" height="38px" marginTop="20px" /> {/* Music */}
        <SkeletonBlock width="300px" height="38px" /> {/* Chorister */}
        <SkeletonBlock width="300px" height="38px" /> {/* Accompanist */}
        <SkeletonBlock width="300px" height="38px" /> {/* Opening Hymn */}
        <SkeletonBlock width="300px" height="38px" /> {/* Sacrament Hymn */}
        <SkeletonBlock width="300px" height="38px" /> {/* Intermediate Hymn */}
        <SkeletonBlock width="300px" height="38px" /> {/* Closing Hymn */}
        
        <SkeletonBlock width="200px" height="38px" marginTop="20px" /> {/* Prayers */}
        <SkeletonBlock width="300px" height="38px" /> {/* Invocation */}
        <SkeletonBlock width="300px" height="38px" /> {/* Benediction */}
        
        <SkeletonBlock width="200px" height="38px" marginTop="20px" /> {/* Speakers */}
        <SkeletonBlock width="300px" height="38px" /> {/* First Speaker */}
        <SkeletonBlock width="300px" height="38px" /> {/* Second Speaker */}
        <SkeletonBlock width="300px" height="38px" /> {/* Third Speaker */}
        <SkeletonBlock width="300px" height="38px" /> {/* Fourth Speaker */}
      </div>
      <div className="page-preview" style={{ padding: "0", border: "none", boxShadow: "none" }}>
        <SkeletonBlock width="100%" height="100%" marginTop="0" /> {/* Page Preview */}
      </div>
    </div>
  );
};