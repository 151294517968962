import { useState } from "react";

export const SundayProgram = ({ isOpen, onClose }) => {
  const [inputImage, setInputImage] = useState(null);
  const [lastChosenImage, setLastChosenImage] = useState(null);
  const [isLoadingInputImage, setIsLoadingInputImage] = useState(true);
  const [isLoadingLastChosenImage, setIsLoadingLastChosenImage] = useState(true);

  if (!isOpen) return null;

  if (!inputImage) {
    fetch(`/api/image?url=${encodeURIComponent('https://storage.googleapis.com/lcrplus-images/feature_modals/sunday-program-input.png')}`)
      .then(res => {
        if (res.ok) return res.blob();
        throw new Error('Failed to fetch image');
      })
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        setInputImage(imageUrl);
        setIsLoadingInputImage(false);
      })
      .catch(error => {
        console.error(`Error fetching photo:`, error);
        setIsLoadingInputImage(false);
      });
  }

  if (!lastChosenImage) {
    fetch(`/api/image?url=${encodeURIComponent('https://storage.googleapis.com/lcrplus-images/feature_modals/sunday-program-last-chosen.png')}`)
      .then(res => {
        if (res.ok) return res.blob();
        throw new Error('Failed to fetch image');
      })
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        setLastChosenImage(imageUrl);
        setIsLoadingLastChosenImage(false);
      })
      .catch(error => {
        console.error(`Error fetching photo:`, error);
        setIsLoadingLastChosenImage(false);
      });
  }

  if (isLoadingInputImage || isLoadingLastChosenImage) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ textAlign: 'left', position: 'relative', maxWidth: '500px' }}>
        <div
          style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px", zIndex: 20000 }}
          onClick={onClose}
        >
          <i className="bx bx-x" style={{ fontSize: "22px" }}></i>
        </div>
        <h2 style={{ textAlign: 'center' }}>🎶 Sunday program 🎶</h2>
        <br/>
        <span>This feature provides a clean and easy way to create programs.</span>
        <br />
        <br />
        {inputImage && <img src={inputImage} alt="Sunday program" style={{ height: '260px', marginRight: '8px', border: '3px solid #ddd', borderRadius: '4px' }} />}
        {lastChosenImage && <img src={lastChosenImage} alt="Sunday program" style={{ height: '260px', border: '3px solid #ddd', borderRadius: '4px' }} />}
      </div>
    </div>
  );
};