import { useState } from "react";

export const FlashcardsMatchMode = ({ isOpen, onClose }) => {
  const [image, setImage] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(true);

  if (!isOpen) return null;

  // Fetch image if not already loaded
  if (!image) {
    fetch(`/api/image?url=${encodeURIComponent('https://storage.googleapis.com/lcrplus-images/feature_modals/flashcards-match-mode.gif')}`)
      .then(res => {
        if (res.ok) return res.blob();
        throw new Error('Failed to fetch image');
      })
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        setImage(imageUrl);
        setIsLoadingImage(false);
      })
      .catch(error => {
        console.error(`Error fetching photo:`, error);
        setIsLoadingImage(false);
      });
  }

  if (isLoadingImage) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ textAlign: 'left', position: 'relative', maxWidth: '480px' }}>
        <div
          style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px", zIndex: 20000 }}
          onClick={onClose}
        >
          <i className="bx bx-x" style={{ fontSize: "22px" }}></i>
        </div>
        <h2 style={{ textAlign: 'center' }}>🧩 Flashcards "match" mode 🧩</h2>
        <br/>
        <p>In addition to the "classic" and "quiz" modes, check out the "match" mode!</p>
        <br/>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {image && (
            <img
              src={image}
              alt="Flashcards match mode"
              style={{
                width: 'calc(100% - 8px)', // Adjust width to account for cropping
                maxWidth: 'calc(480px - 8px)', // Adjust maxWidth similarly
                borderRadius: '4px',
                objectFit: 'cover', // Ensures the image covers the container
                objectPosition: 'center', // Centers the image
                clipPath: 'inset(4px)', // Crops 2 pixels from each side
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
