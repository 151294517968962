import { useState } from "react";
import { SkeletonSundayProgram } from "../skeletons/SkeletonSundayProgram";
import { SkeletonRows } from "../skeletons/SkeletonRows";
import { lazy, Suspense, useRef } from "react";
const Editor = lazy(() => import("./Editor"));
const History = lazy(() => import("./History"));

const SundayPrograms = ({ membersList, setMembersList, sustainings, releases, issuersList, membersWithCallings, handleReleaseMember, unitDetails, sessionInfo, organizations, createSustaining }) => {
  const initialLoad = useRef(true);
  const [activeTab, setActiveTab] = useState("editProgram");
  const [hymns, setHymns] = useState([]);

  if (initialLoad.current && membersList.length > 0) {
    const loadData = async () => {
      const hymnsResponse = await fetch('/api/list_hymns');
      const hymnsData = await hymnsResponse.json();
      setHymns(hymnsData);
    };

    loadData();
    initialLoad.current = false;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <div className="template-menu">
        <button onClick={() => setActiveTab("editProgram")} className={activeTab === "editProgram" ? "active" : ""}>{unitDetails.language === "es" ? "Editar programa" : "Edit program"}</button>
        <button onClick={() => setActiveTab("viewHistory")} className={activeTab === "viewHistory" ? "active" : ""}>{unitDetails.language === "es" ? "Ver historial" : "View history"}</button>
      </div>
      {activeTab === "editProgram" && (membersList.length === 0 ? <SkeletonSundayProgram /> : <Suspense fallback={<SkeletonSundayProgram />}><Editor membersList={membersList} sustainings={sustainings} releases={releases} issuersList={issuersList} membersWithCallings={membersWithCallings} handleReleaseMember={handleReleaseMember} unitDetails={unitDetails} organizations={organizations} createSustaining={createSustaining} hymns={hymns} /></Suspense>)}
      {activeTab === "viewHistory" && (membersList.length === 0 ? <SkeletonRows /> : <Suspense fallback={<SkeletonRows />}><History membersList={membersList} issuersList={issuersList} unitDetails={unitDetails} sessionInfo={sessionInfo} setMembersList={setMembersList} hymns={hymns}/></Suspense>)}
    </div>
  )
}

export default SundayPrograms;
