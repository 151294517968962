import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LogoHorizontal } from '../logos/LogoHorizontal';
import { formatName } from '../utils';
import { NoProfilePicture } from '../shared/NoProfilePicture';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import CloseIcon from '@mui/icons-material/Close';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import SwitchAccountRoundedIcon from '@mui/icons-material/SwitchAccountRounded';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import QueueMusicRoundedIcon from '@mui/icons-material/QueueMusicRounded';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';

export const Menu = ({ sessionInfo, unitDetails, activeTab, handleTabSelection, isOpen, onClose }) => {
  const drawerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const initialLoad = useRef(true);

  if (initialLoad.current) {
    const validPaths = ["callings-by-organization", "ministering"];

    if (sessionInfo.accessLevel === "BISHOPRIC") {
      validPaths.push("home");
    }

    if (["EQ_PRESIDENCY", "RS_PRESIDENCY", "SS_PRESIDENCY"].includes(sessionInfo.accessLevel)) {
      validPaths.push("my-organization");
    }

    if (["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY", "WARD_MISSION"].includes(sessionInfo.accessLevel)) {
      validPaths.push("member-reports", "roommates");
    }

    if (["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY", "COMMUNICATION"].includes(sessionInfo.accessLevel) && unitDetails.communication_enabled) {
      validPaths.push("communication");
    }

    if (sessionInfo.isWelcomeNotificationRecipient || sessionInfo.isTithingDeclarationNotificationRecipient || 
        sessionInfo.isTempleRecommendNotificationRecipient || sessionInfo.isSettingApartNotificationRecipient || 
        sessionInfo.isPreviousBishopNotificationRecipient || sessionInfo.isNextBishopNotificationRecipient || 
        sessionInfo.isOnboardingNotificationRecipient || sessionInfo.isBirthdayNotificationRecipient || 
        sessionInfo.isMinisteringNotificationRecipient) {
      validPaths.push("message-templates");
    }

    if (["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel)) {
      validPaths.push("flashcards");
    }

    if (["BISHOPRIC"].includes(sessionInfo.accessLevel)) {
      validPaths.push("sunday-program");
    }

    if (validPaths.includes(location.pathname.split('/')[1])) {
      handleTabSelection(location.pathname.split('/')[1].replace(/-([a-z])/g, (_, letter) => letter.toUpperCase()));
    } else {
      handleTabSelection(sessionInfo.accessLevel === "BISHOPRIC" ? "home" : ["EQ_PRESIDENCY", "RS_PRESIDENCY", "SS_PRESIDENCY"].includes(sessionInfo.accessLevel) ? "myOrganization" : "callingsByOrganization")
    }

    initialLoad.current = false;
  }

  // Close drawer if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleNavigation = (tab, path) => {
    onClose();
    handleTabSelection(tab);
    navigate(`/${path}`);
  };

  return (
    <>
      {isOpen && <div className="overlay" onClick={onClose} />}
      <div className={`side-drawer ${unitDetails.language} ${isOpen ? 'open' : ''}`} ref={drawerRef}>
        <button
          className="close-btn"
          onClick={onClose}
          style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
        >
          <CloseIcon sx={{ fontSize: 18 }} />
        </button>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: '1 1 auto',
        }}>
          <div style={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}>
            <div style={{ marginTop: "16px", marginLeft: "16px", marginBottom: "8px" }}>
              <LogoHorizontal scaleFactor={1.2} />
            </div>
            <hr
              style={{ 
                border: "none", 
                borderTop: "1px solid #f0f0f0",
                width: "248px",
                marginLeft: "16px",
                marginRight: "16px",
              }}
            />
            <div
              style={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '8px',
                width: '248px',
                margin: "16px",
              }}
            >
              {sessionInfo.accessLevel === "BISHOPRIC" &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "home" ? "selected" : ""}`}
                  onClick={() => handleNavigation("home", "home")}
                >
                  <PendingRoundedIcon className="icon" />
                  {unitDetails.language === "es" ? "Llamamientos pendientes" : "Pending callings"}
                </button>
              }
              {["EQ_PRESIDENCY", "RS_PRESIDENCY", "SS_PRESIDENCY"].includes(sessionInfo.accessLevel) &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "myOrganization" ? "selected" : ""}`}
                  onClick={() => handleNavigation("myOrganization", "my-organization")}
                >
                  <HomeRoundedIcon className="icon" />
                  {unitDetails.language === "es" ? "Mi organización" : "My organization"}
                </button>
              }
              <button
                className={`main-menu-dropdown-item ${activeTab === "callingsByOrganization" ? "selected" : ""}`}
                onClick={() => handleNavigation("callingsByOrganization", "callings-by-organization")}
              >
                <DeviceHubRoundedIcon className="icon" />
                {unitDetails.language === "es" ? "Llamamientos por organización" : "Callings by organization"}
              </button>
              {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY", "WARD_MISSION"].includes(sessionInfo.accessLevel) &&
                <>
                  <button
                    className={`main-menu-dropdown-item ${activeTab === "memberReports" ? "selected" : ""}`}
                    onClick={() => handleNavigation("memberReports", "member-reports")}
                  >
                    <PeopleAltRoundedIcon className="icon" />
                    {unitDetails.language === "es" ? "Informes de miembros" : "Member reports"}
                  </button>
                </>
              }
              {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY", "COMMUNICATION"].includes(sessionInfo.accessLevel) && unitDetails.communication_enabled &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "communication" ? "selected" : ""}`}
                  onClick={() => handleNavigation("communication", "communication")}
                >
                  <SmsRoundedIcon className="icon" />
                  {unitDetails.language === "es" ? "Comunicación" : "Communication"}
                </button>
              }
              {(sessionInfo.isWelcomeNotificationRecipient || sessionInfo.isTithingDeclarationNotificationRecipient || sessionInfo.isTempleRecommendNotificationRecipient || sessionInfo.isSettingApartNotificationRecipient || sessionInfo.isPreviousBishopNotificationRecipient || sessionInfo.isNextBishopNotificationRecipient || sessionInfo.isOnboardingNotificationRecipient || sessionInfo.isBirthdayNotificationRecipient || sessionInfo.isMinisteringNotificationRecipient) &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "messageTemplates" ? "selected" : ""}`}
                  onClick={() => handleNavigation("messageTemplates", "message-templates")}
                >
                  <TextSnippetRoundedIcon className="icon" />
                  {unitDetails.language === "es" ? "Plantillas de mensajes" : "Message templates"}
                </button>
              }
              <button
                className={`main-menu-dropdown-item ${activeTab === "ministering" ? "selected" : ""}`}
                onClick={() => handleNavigation("ministering", "ministering")}
              >
                <VolunteerActivismRoundedIcon className="icon" />
                {unitDetails.language === "es" ? "Ministerio" : "Ministering"}
              </button>
              {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY", "WARD_MISSION"].includes(sessionInfo.accessLevel) &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "roommates" ? "selected" : ""}`}
                  onClick={() => handleNavigation("roommates", "roommates")}
                >
                  <HomeRoundedIcon className="icon" />
                  {unitDetails.language === "es" ? "Compañeros de cuarto" : "Roommates"}
                </button>
              }
              {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel) &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "flashcards" ? "selected" : ""}`}
                  onClick={() => handleNavigation("flashcards", "flashcards")}
                >
                  <SwitchAccountRoundedIcon className="icon" />
                  {unitDetails.language === "es" ? "Tarjetas de memoria" : "Flashcards"}
                </button>
              }
              {/* {unitDetails.id === 2 &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "bishopInterviews" ? "selected" : ""}`}
                  onClick={() => handleNavigation("bishopInterviews", "bishop-interviews")}
                >
                  <CalendarMonthIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Entrevistas obispo" : "Bishop interviews"}
                </button>
              } */}
              {/* {["BISHOPRIC"].includes(sessionInfo.accessLevel) &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "sundayProgram" ? "selected" : ""}`}
                  onClick={() => handleNavigation("sundayProgram", "sunday-program")}
                >
                  <PictureAsPdfOutlinedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Programa dominical" : "Sunday program"}
                  <span style={{ marginLeft: "32px", fontSize: "12px", fontWeight: "600", color: "#12B981", backgroundColor: "#D1FAE5", borderRadius: "4px", border: "1px solid #12B981", padding: "2px 6px" }}>
                    {unitDetails.language === "es" ? "NUEVO" : "NEW"}
                  </span>
                </button>
              } */}
              {["BISHOPRIC"].includes(sessionInfo.accessLevel) &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "sundayProgram" ? "selected" : ""}`}
                  onClick={() => handleNavigation("sundayProgram", "sunday-program")}
                >
                  <PictureAsPdfOutlinedIcon className="icon" />
                  {unitDetails.language === "es" ? "Programa dominical" : "Sunday program"}
                  {/* <span style={{ marginLeft: "32px", fontSize: "12px", fontWeight: "600", color: "#12B981", backgroundColor: "#D1FAE5", borderRadius: "4px", border: "1px solid #12B981", padding: "2px 6px" }}>
                    {unitDetails.language === "es" ? "NUEVO" : "NEW"}
                  </span> */}
                </button>
              }
              {false && sessionInfo.accessLevel === "MUSIC" &&
                <button
                  className={`main-menu-dropdown-item ${activeTab === "sundayMusic" ? "selected" : ""}`}
                  onClick={() => handleNavigation("sundayMusic", "sunday-music")}
                >
                  <QueueMusicRoundedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Música dominical" : "Sunday music"}
                </button>
              }
              {/* {sessionInfo.accessLevel === "BISHOPRIC" &&
                <DropdownMenuRadioItem
                  value="covenantPath"
                  className={`main-menu-dropdown-item ${activeTab === "covenantPath" ? "selected" : ""}`}
                  disabled
                >
                  <i className="bx bxs-direction-right" style={{ marginRight: '5px', fontSize: "16px", position: 'relative', top: '2px' }}></i>
                  {unitDetails.language === "es" ? "Sendero de convenios" : "Covenant path"}
                  <span style={{ marginLeft: "22px", fontSize: "12px", backgroundColor: "#abf2b8", borderRadius: "4px", border: "2px", padding: "1px" }}>
                    {unitDetails.language === "es" ? "PRÓXIMAMENTE" : "COMING SOON"}
                  </span>
                </DropdownMenuRadioItem>
              } */}
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            <hr style={{ 
                border: "none", 
                borderTop: "1px solid #f0f0f0",
                marginLeft: "16px",
                marginRight: "16px",
                width: "248px",
            }}/>
            <div style={{ display: "flex", alignItems: "center", fontSize: '16px', margin: "16px", gap: "8px" }}>
              {(sessionInfo.member_photo || sessionInfo.household_photo) ?
                <img
                  src={sessionInfo.member_photo || sessionInfo.household_photo}
                  alt={sessionInfo.name}
                  style={{ width: "35px", height: "35px", borderRadius: "50%", border: "0.5px solid #B0B5C0", objectFit: "cover" }}
                /> : <NoProfilePicture styles={{ width: "35px", height: "35px" }} rounded={true} />
              }
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span style={{ fontWeight: "bold" }}>{formatName(sessionInfo.name)}</span>
                {sessionInfo.position && <span style={{ fontStyle: "italic", fontSize: "14px", textAlign: "left" }}>{sessionInfo.position}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
